html {
  height: 100% !important;
}

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100% !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*, ::after, ::before {
  box-sizing: border-box;
}

.uppercase {
  text-transform: uppercase;
}

#root {
  height: 100% !important;
}

#semantic-map {
  height: 100%;
}

#openseadragon-word, #openseadragon-doc {
  height: calc(100% - 50px);
  background: black;
}

#openseadragon-search {
  height: 100%;
  background: black;
}

#selection-word, #selection-doc {
  width: 40px;
  height: 40px;
  border: 2px solid red;
  position: relative;
  z-index: 2048;
}

.navigator {
  z-index: 4096;
}

@media (max-height: 768px), (max-width: 768px) {
  div[id^='navigator'].navigator {
    display: none !important;
  }
}

.highlight {
  opacity: 0.5;
  background: lightblue;
}

.center {
  background-color: white;
  width: 10px;
  height: 10px;
  position: relative;
  border-radius: 50%;
  z-index: 1024;
}

.center-important {
  background-color: red;
}

.hidden {
  visibility: hidden;
}

.center:after {
  content: "";
  position: absolute;
  margin-top: 10px;
  text-align: center;
  color: white;
  left: 50%;
   -webkit-transform: translateX(-50%);
   -moz-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 100vw;
}

.main-container {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  height: calc(100% - 66px);
  height: calc(var(--vh, 1vh) * 100 - 66px);
  width: 100%;
  transition: margin-left 0.5s;
}

.main-container-left-collapsed {
  grid-template-columns: 0 3fr 1fr;
  height: calc(100% - 66px);
  height: calc(var(--vh, 1vh) * 100 - 66px);
}

.main-container-right-collapsed {
  grid-template-columns: 1fr 3fr 0;
}

.main-container-both-collapsed {
  grid-template-columns: 0 3fr 0;
}


.divider {
  background: white;
  opacity: 0.1;
  width: 100%;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.header-medium {
  color: white;
  font-size: 14px;
  text-transform: uppercase;
}

/* Custom scrollbar - MacOS style */
::-webkit-scrollbar {
  width: 8px;
  background-color: #a0a0a5;
  -webkit-border-radius: 100px;
  z-index: 16384;
}

::-webkit-scrollbar:horizontal {
  height: 8px;
}

::-webkit-scrollbar:hover {
  background-color: #b2b2b7;
}

::-webkit-scrollbar-thumb:vertical, ::-webkit-scrollbar-thumb:horizontal {
  background: rgba(0,0,0,0.5);
  -webkit-border-radius: 100px;
}
::-webkit-scrollbar-thumb:vertical:active, ::-webkit-scrollbar-thumb:horizontal:active {
  background: #6e6e73;
  -webkit-border-radius: 100px;
}
