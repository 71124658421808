.topics-sidebar {
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    height: calc(100vh - 66px);
    height: calc(var(--vh, 1vh) * 100 - 66px);
    min-width: 300px;
    background: #2F2F2F;
    z-index: 4096;
    color: white;
}

.topics-sidebar > h3 {
    font-size: 20px;
}

.topics-sidebar h4 {
    font-size: 14px;
    text-transform: uppercase;
}

.wrapper {
    height: calc(50% - 2.5rem);
    display: flex;
    flex-direction: column;
    margin: 0.25rem 0.25rem;
}

